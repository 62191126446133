.fm-context-menu {
  position: fixed;
  background-color: #343536;
  border: 1px solid rgb(45, 45, 45);
  border-radius: 6px;
  padding: 4px;
  z-index: 1;
  transition: opacity 0.1s linear;

  .file-context-menu-list {
    font-size: 1.1em;

    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 3px;

      li {
        display: flex;
        gap: 9px;
        align-items: center;
        padding: 3px 13px;
        position: relative;
        border-radius: 4px;

        &:hover {
          cursor: pointer;
          background-color: #535353;
        }
      }

      li.active {
        background-color: #535353;
      }

      li.disable-paste {
        opacity: 0.5;

        &:hover {
          cursor: default;
          background-color: transparent;
        }
      }
    }

    .divider {
      border-bottom: 1px solid #c6c6c6;
      margin: 5px 0 3px 0;
    }

    .list-expand-icon {
      margin-left: auto;
      color: white;
    }

    .sub-menu {
      position: absolute;
      top: 0;
      background-color: #343536;
      border: 1px solid rgb(45, 45, 45);
      border-radius: 6px;
      padding: 4px;
      z-index: 1;

      .item-selected {
        width: 13px;
        color: white;
      }

      li {

        &:hover {
          background-color: #535353 !important;
        }
      }
    }

    .sub-menu.right {
      left: 100%;
    }

    .sub-menu.left {
      left: calc(-94%);
    }
  }
}

.fm-context-menu.hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.fm-context-menu.visible {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}