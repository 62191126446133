.fm-rename-folder-container {
  padding: 8px 0;

  .fm-rename-folder-input {
    border-bottom: 1px solid #c6c6c6;
    padding: 8px 12px 12px;

    .fm-rename-warning {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 1.2em;
    }
  }

  .fm-rename-folder-action {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    padding: 8px 8px 0 0;
  }
}