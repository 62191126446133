.files {
  position: relative;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  column-gap: 0.5em;
  row-gap: 5px;
  height: calc(100% - (38px + 4px));
  overflow-y: auto;
  padding-right: 4px;
  color: white;
  font-size: 11px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .drag-move-tooltip {
    background-color: white;
    font-size: .78em;
    position: fixed;
    text-wrap: nowrap;
    border: 1px dashed black;
    padding: 1px 5px 2px 24px;
    border-radius: 3px;
    font-weight: bold;
    color: rgb(29, 29, 29);
    z-index: 2;

    .drop-zone-file-name {
      color: rgb(68, 68, 68);
    }
  }

  .file-item-container {
    border-radius: 5px;
    margin: 5px 0;

    .drag-icon {
      position: absolute !important;
      top: -1000px;
      left: -1000px;
      z-Index: -1;
      border-radius: 4px;
      position: relative;
      color: white;
      background-color: grey;
      padding: 3px 8px;
    }
  }

  .file-item-container.file-drop-zone {
    background-color: rgb(0, 0, 0, 0.08) !important;
  }

  .file-item {
    position: relative;
    height: 89px;
    width: 106px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 1px;
    border-radius: 5px;
    font-weight: 300;

    &:hover {
      background-color: #6a6a6a;
    }

    .fm-modal-header {
      font-size: 1.3em;
    }

    .selection-checkbox {
      position: absolute;
      left: 5px;
      top: 5px;
    }

    .hidden {
      visibility: hidden;
    }

    .visible {
      visibility: visible;
    }

    .rename-file-container {
      position: absolute;
      top: 67px;
      width: 100%;
      text-align: center;
    }

    .rename-file-container.list {
      top: 1px;
      left: 53px;
      text-align: left;

      .rename-file {
        min-width: 15%;
        text-align: left;
        border-radius: 3px;
        /* border: none; */
        top: 5px;
        white-space: nowrap;
        overflow-x: hidden;
        max-width: 100%;
      }

      .folder-name-error.right {
        left: 0px;
        bottom: -72px;
      }
    }

    .file-name {
      max-width: 100px;
      cursor: default;
    }
  }

  .file-selected {
    background-color: #6a6a6a;
    color: white;

    &:hover {
      background-color: #6a6a6a;
    }
  }

  .file-moving {
    opacity: 0.5;
  }
}

.files.list {
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 1px;
  padding-left: 0px;
  padding-top: 0px;

  .files-header {
    font-size: 1em;
    font-weight: 600;
    display: flex;
    gap: 5px;
    border-bottom: 1px solid #9c9c9c;
    padding: 4px 0 4px 5px;
    position: sticky;
    top: 0;
    background-color: #535353;
    z-index: 1;
    margin-bottom: 2px;

    .file-select-all {
      width: 5%;
      height: 0.83em;
    }

    .file-name {
      width: calc(65% - 45px);
      padding-left: 10px;
    }

    .file-date {
      text-align: center;
      width: 20%;
    }

    .file-size {
      text-align: center;
      width: 10%;
    }
  }

  .file-item-container {
    display: flex;
    width: 100%;
    margin: 0;
    border-radius: 0;

    &:hover {
      background-color: #6a6a6a;
    }
  }

  .file-item-container.file-selected {
    &:hover {
      background-color: #6a6a6a !important;
    }
  }

  .file-item {
    flex-direction: row;
    height: 13px;
    justify-content: unset;
    gap: 0.5rem;
    padding: 15px;
    padding-left: 27px;
    margin: 0;
    width: calc(70% - 30px);

    &:hover {
      background-color: unset;
    }

    .selection-checkbox {
      top: 8px;
    }

    .file-name {
      max-width: 285px;
    }
  }

  .modified-date {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    width: calc(20%);
    cursor: default;
  }

  .size {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    width: calc(10%);
    cursor: default;
  }
}

.empty-folder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}