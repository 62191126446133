/* @import "../../styles/variables"; */

.bread-crumb-container {
  position: relative;

  .breadcrumb {
    min-height: 18px;
    max-height: 18px;
    display: flex !important;
    gap: 0.4rem;
    overflow-x: hidden;
    margin-bottom: 0;

    line-height: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: top;
    font-family: Verdana, sans-serif;
    font-size: 11px;
    color: #fff;

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: grey !important;
    }

    .folder-name {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      font-weight: 500;
      min-width: fit-content;

      &:hover {
        cursor: pointer;
        color: #ffffffab;
      }
    }

    .curr-folder {
      color: #ffffffab;
    }

    .hidden-folders {
      padding: 0 4px;
    }

    .folder-name-btn {
      background-color: transparent;
      border: none;
      padding: 0;

      &:hover,
      &:focus {
        cursor: pointer;
        color: grey;
        background-color: #dddcdc;
        border-radius: 5px;
      }
    }

  }
}

.hidden-folders-container {
  position: absolute;
  margin: 0;
  z-index: 2;
  background-color: rgb(99, 99, 99);
  color: white;
  padding: 4px;
  border-radius: 5px;
  font-size: .9em;
  left: 3rem;
  display: flex;
  flex-direction: column;
  gap: 5px;

  li {
    padding: 5px 10px;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      background-color: rgb(117, 117, 117);
    }
  }
}