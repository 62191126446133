.fm-checkbox {
    accent-color: white;

    &:disabled {
        cursor: default !important;
    }

    &:hover {
        cursor: pointer;
    }

}