.sb-folders-list-item {
    display: flex;
    gap: 5px;
    color: white;
    font-size: 11px;
    padding: 0.4rem .5rem;
    cursor: pointer;

    &:hover {
        background-color: #6a6a6a;
    }
}

.no-folder-content {
    padding-left: 24px;
}

.active-list-item {
    background-color: #6a6a6a;
}

.non-expanable {
    min-width: 12px;
}

.folder-collapsible {
    margin-left: 10px;
}

.sb-folder-details {
    display: flex;
    gap: 8px;
}

.folder-rotate-down {
    animation: rotateFolderDown 0.5s linear forwards;
}

@keyframes rotateFolderDown {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
    }
}

.folder-rotate-right {
    animation: rotateRight 0.5s linear forwards;
}

@keyframes rotateRight {
    from {
        transform: rotate(90deg);
    }

    to {
        transform: rotate(0deg);
    }
}