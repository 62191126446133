.fm-button {
  border-radius: 5px;
  font-weight: 600;
  font-size: .84em;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.fm-button-primary {
  background-color: #d49bf7;
  color: rgb(32, 32, 32);

  &:hover {
    background-color: #b583d4;
  }
}

.fm-button-secondary {
  background-color: #e0e0e0;
  color: black;

  &:hover {
    background-color: #c9c9c9;
  }
}

.fm-button-danger {
  background-color: #f44336;
  color: white;

  &:hover {
    background-color: #d32f2f;
  }
}