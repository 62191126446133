.add-new-btn {
    background-color: #343537;
    padding: 0.6rem 1.7rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.add-new-btn button {
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
}
.add-new-btn button i {
    color: #d49bf7;
}
.add-new-btn button:hover {
    color: #fff;
}
div.offcanvas {
    background: #232323;
    color: #fff;
}
.offcanvas .offcanvas-header .btn-close {
    border-color: #fff;
    filter: invert(1);
}
.offcanvas label.form-label {
    font-family: "Inter", sans-serif;
    opacity: .7;
    font-size: 13px;
    font-weight: normal;
}
.offcanvas .form-control, .offcanvas .form-select, .offcanvas input#chooseTheme {
    height: 30px;
    background-color: #4a4a4a;
    background-image: none;
    border-radius: 3px !important;
    border-color: #777777;
    padding: 5px;
    color: #fff;
    font-size: 11px;
    border: 1px solid #777777;
}
.offcanvas ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
  opacity: 1;
}
.offcanvas ::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
  opacity: 1;
}
.offcanvas :-ms-input-placeholder { /* IE 10+ */
  color: #fff;
  opacity: 1;
}
.offcanvas :-moz-placeholder { /* Firefox 18- */
  color: #fff;
  opacity: 1;
}
.offcanvas i {
    position: absolute;
    right: 14px;
    bottom: 4px;
}
.browseBtn input {
    opacity: 0;
    position: absolute;
    z-index: 123;
}
.browseBtn button.btn {
    border-radius: 5px !important;
    background: #535353;
    float: right;
    height: 30px;
    font-size: 12px;
    min-width: 28%;
    border: 1px solid #535353;
    margin-left: .5rem !important;
}
.browseBtn button.btn:hover {
    background-color: rgb(96 93 93 / 60%);
}
.solution {
    padding: 2rem 1.7rem;
}
.solution table tr th, .solution table tbody tr td {
    background-color: transparent;
    font-size: 13px;
    vertical-align: middle;
    font-weight: 400;
    color: #fff;
    border-color: #3d3e40;
}
.solution table tbody tr td {
    color: #e4e4e4;
}
.solution table tr th:first-child {
    padding-left: 0;
}
.solution table tr th a {
    text-decoration: none;
    color: #e4e4e4;
    transition: 0.3s;
}
.solution table tr th a:hover {
    color: #fff;
    text-decoration: underline;
}
.solution table td .dropdown button.dropdown-toggle {
    border-radius: 0;
    display: inline-block;
    padding: 3px !important;
    position: absolute;
    top: -21px;
    bottom: -21px;
}
.solution table td .dropdown button.dropdown-toggle:hover {
    background: #3d3e40 !important;
}
.solution table td .dropdown button.dropdown-toggle:after {
    display: none;
}
.solution table td .dropdown button.dropdown-toggle i {
    color: #e4e4e4;
    font-size: 13px;
}
.solution .table tbody tr td .dropdown-menu {
    background-color: #3d3e40;
    border-radius: 0;
    border: 0;
    padding: 0;
}
.solution .table tbody tr td .dropdown-menu button.dropdown-item {
    font-size: 12px;
    border-bottom: 1px solid #dddddd14;
    padding: 8px;
    color: #fff;
    transition: 0.3s;
}
.solution .table tbody tr td .dropdown-menu button.dropdown-item:hover, .solution .tbuttonble tbody tr td .dropdown-menu a.dropdown-item:focus {
    background-color: #343537;
}
.setingsTabs ul {
    border-bottom: 1px solid #535353;
}
.setingsTabs ul li button {
    background: transparent;
    font-size: 16px;
    color: #fff;
    position: relative;
    border: 0 !important;
}
.setingsTabs ul .nav-link.active, .setingsTabs ul .show > .nav-link, .setingsTabs ul .nav-link:hover {
    background-color: transparent;
    border: 0;
    color: #fff;
}
.setingsTabs ul li button:after {
    border-bottom: 3px solid transparent;
    position: absolute;
    content: "";
    bottom: 0;
    width: 100%;
    left: 0;
    border-radius: 100px 100px 0 0;
}
.setingsTabs ul .nav-link.active:after, .setingsTabs ul .nav-link:hover:after {
    border-color: #c824c8;
}
.setingsTabs .form-control {
    max-width: 40%;
}
.w-40  {
    width: 40%;
}
.setingsTabs label {
    min-width: 135px;
}
.offcanvas input.form-control-color {
    background-color: transparent;
    padding: 0;
    border: 2px solid;
    border-radius: 0 !important;
    height: 1.6em;
    width: 3em;
    margin-left: 0.4rem;
}
.offcanvas label.colorLabel {
    min-width: auto;
    margin-right: 0.7rem;
}
.virtualMain footer {
    margin: 0 !important;
    color: #fff !important;
    background-color: #3d3e40;
    padding: 0.5rem 0;
    display: none;
}
.colorInput {
  -webkit-appearance: none;
  padding: 0;
  border-radius: 0;
  width: 20px;
  height: 20px;
}
.colorInput::-webkit-color-swatch {
  border-radius: 0;
  padding: 0;
}
.colorInput::-webkit-color-swatch-wrapper {
  border-radius: 0;
  padding: 0;
}
.modalCard .modal-dialog {
    max-width: 40rem;
}
.solution-table{min-height: calc(100vh - 202px);}
span.badge {
    min-width: 75px;
}
span.bg-success {
    background: #affebf !important;
    color: #014b40;
    font-weight: 400;
    font-size: 12px;
    border-radius: 100px;
    padding: 7px 12px;
}
span.bg-info {
    font-weight: 400;
    font-size: 12px;
    border-radius: 100px;
    padding: 7px 12px;
}
span.badge.bg-danger {
    border-radius: 100px;
    padding: 7px 12px;
    font-weight: 400;
    font-size: 12px;
}
@media (min-width: 768px) and (max-width: 991px) {
.w-50 {
    width: 80%!important;
}
    
}  

@media (max-width: 767px) {
.virtualMain {
    width: 100%;
}
.solution table tr th, .solution table tbody tr td {
    white-space: nowrap;
}
.settingCanvas.w-50 {
    width: 100% !important;
}
.settingCanvas .px-5.offcanvas-header, .settingCanvas .setingsTabs.px-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.offcanvas label.form-label {
    font-size: 11px;
}
.add-new-btn {
    padding: 0.6rem 1rem;
}
.solution {
    padding: 2rem 1rem;
}
.justify-content-end.justify-start {
    justify-content: flex-start !important;
}



}