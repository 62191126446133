.fm-upload-file {
    padding: 18px 15px;
    display: flex;
    gap: 18px;

    .select-files {
        width: 100%;

        .draggable-file-input {
            color: white;
            background-color: #686868;
            margin-bottom: 18px;
            height: 220px;
            border: 2px dashed #a7a7a7;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            .input-text {
                pointer-events: none;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &:hover {
                border-color: #ebebeb;
            }
        }

        .draggable-file-input.dragging {
            border-color: #ebebeb;
        }

        .btn-choose-file {
            display: flex;
            justify-content: center;

            label {
                display: inline-block;
                padding: 0.3rem 0.7rem;

                &:hover {
                    cursor: pointer;
                }
            }

            .choose-file-input {
                display: none;
            }
        }
    }

    .files-progress {
        width: 60%;
        color: white;

        .heading {
            display: flex;
            gap: 4px;
        }

        h2 {
            font-size: 0.9em;
            margin: 0;
        }

        ul {
            padding-left: 0px;
            padding-right: 5px;
            padding-bottom: 10px;
            margin-top: 0.7rem;
            height: 220px;
            overflow-y: auto;
            font-size: 14px;

            li {
                list-style: none;
                border-bottom: 1px solid #c6c6c6;
                display: flex;
                gap: 5px;
                margin-bottom: 18px;
                padding-bottom: 12px;

                .file-icon {
                    width: 15%;
                }

                .file {
                    width: 85%;

                    .file-details {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 5px;

                        .file-info {
                            width: 90%;
                            display: flex;
                            align-items: baseline;

                            .file-name {
                                display: inline-block;
                                max-width: 72%;
                                margin-right: 8px;
                            }
                        }

                        .file-size {
                            font-size: 0.7em;
                        }

                        .retry-upload {
                            padding: 3px;
                            border-radius: 50%;

                            &:hover {
                                cursor: pointer;
                                background-color: #6a6a6a;
                            }
                        }

                        .rm-file {
                            padding: 0 5px 2px 5px;
                            border-radius: 50%;

                            &:hover {
                                background-color: #6a6a6a;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}