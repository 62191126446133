.toolbar {
  height: 38px;
  min-height: 38px;
  max-height: 38px;
  padding: 3px 10px 3px 0px;
  display: flex;
  align-items: center;

  .file-action-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    >div {
      display: flex;
      gap: 1px;
    }

    .file-action {
      background-color: transparent;
      gap: 5px;

      &:hover:not(:disabled) {
        cursor: pointer;
        background-color: #6a6a6a !important;
        border-radius: 3px;
        color: white;
        text-shadow: 0px 0px 1px white;
      }

      &:hover:disabled {
        cursor: default;
        background-color: transparent !important;
        color: #b0b0b0;
        text-shadow: none;
      }
    }
  }

  .fm-toolbar {
    display: flex;
    justify-content: space-between;
    width: 100%;

    >div {
      display: flex;
      position: relative;
    }

    .toolbar-left-items {
      display: flex;
    }

    .toggle-view {
      position: absolute;
      z-index: 3;
      top: 105%;
      right: 22%;
      background-color: #343536;
      margin: 0;
      border: 1px solid rgb(45, 45, 45);
      border-radius: 5px;
      color: white;
      font-size: 12px;

      ul {
        list-style: none;
        padding-left: 0;
        margin: 0.4em 0;
        display: flex;
        flex-direction: column;
        gap: 1px;

        li {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 3px 18px 3px 9px;

          &:hover {
            cursor: pointer;
            background-color: #535353;
          }

          span:nth-child(1) {
            width: 12px;
          }
        }
      }
    }
  }

  .item-action {
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 5px 10px;
    font-size: 12px;
    width: fit-content;
    border: none;
    color: white;

    &:hover {
      cursor: pointer;
      background-color: #6a6a6a !important;
      border-radius: 3px;
    }

    .toggle-view-icon {
      background-color: transparent;
      border: none;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .icon-only {
    padding: 0 8px !important;

    &:focus {
      background-color: #6a6a6a;
      border-radius: 3px;
    }
  }

  .item-separator {
    align-self: center;
    height: 24px;
    background: white;
    width: 1px;
    margin: 0 5px;
  }
}

.file-selected {
  background-color: transparent;
}