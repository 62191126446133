.fm-modal {
  margin-top: auto;
  border: 1px solid #111111;
  border-radius: 5px;
  width: fit-content;
  overflow-x: hidden;
  padding: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.253);
  background-color: #343536;
}

.close-icon {
  padding: 5px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background-color: #6a6a6a;
  }
}

.fm-modal-header {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(151, 151, 151);
  padding: 0.3rem 1rem;

  .fm-modal-heading {
    margin: 0;
    font-weight: bold;
  }
}

.dialog[open] {
  animation: expand 0.4s forwards;

  &::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
}

@keyframes expand {
  from {
    transform: scale(0.4);
  }

  to {
    transform: scale(1);
  }
}