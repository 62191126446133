.rename-file {
    position: relative;
    min-width: 69%;
    max-width: -webkit-fill-available;
    text-align: center;
    border: 1px solid white;
    border-radius: 4px;
    font-size: 11px;
    padding: 1px 5px;
    resize: none;
    field-sizing: content;
    overflow-y: hidden;
    background-color: #535353;
    color: white;

    &:focus {
        outline: none;
    }
}